<template>
<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <div class="container-fluid">
    <a class="navbar-brand" href="/">Snail Racing</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls=".navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav me-auto">
        <li class="nav-item">
          <a class="nav-link" href="/">Home</a>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/commands">Commands</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/items">Items</router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://discord.io/snail">Support</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script>
export default {
  mounted() {
    let jquery = document.createElement("script");
    let popper = document.createElement("script");
    let bootstrap = document.createElement("script");

    jquery.setAttribute("src", "https://code.jquery.com/jquery-3.3.1.slim.min.js");
    popper.setAttribute("src", "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js");
    bootstrap.setAttribute("src", "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js");

    document.head.append(jquery, popper, bootstrap);
  }
}
</script>