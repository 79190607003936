<template>
  <section class="section">
    <div class="container-fluid" id="main">
      <h2 class="text-center">It's Time To Race</h2>
      <img alt="logo" class="rounded-circle center" src="@/assets/snail.webp" width=200 height=200>
      <h4 class="text-center">Snail Racing is a fun and easy to use bot currently used by {{guilds[0]["total"]}} guilds!</h4>
      <br>
        <div class="text-center">
          <div class="btn-group col-md-auto text-center">
            <a type="button" class="btn btn-primary btn-lg" href="https://discord.com/oauth2/authorize?client_id=256556410031046657&scope=bot&permissions=281600"><em class="fas fa-robot"></em> Add to Discord</a>
            <a type="button" class="btn btn-info btn-lg" href="https://discord.io/snail"><em class="fab fa-discord"></em> Support Guild</a>
          </div>
        </div>
    </div>
  </section>
</template>

<style lang="css" scoped>
    .section{
      padding: 5%;
    }
    .center{
      display:block;
      margin-left: 44.25%;
      margin-right: auto;
    }
</style>

<script>
import axios from "axios";

export default {
  name: "guilds",
  data() {
    return {
      guilds: []
    };
  },

  created() {
    this.getGuilds();
  },

  methods: {
    async getGuilds() {
      try {
        const res = await axios.get("https://snailracing.co/api/guilds");
        this.guilds = res.data;
      } catch (err) {
        this.guilds = "[{'total': 'thousands of'}]"
        console.log(err);
      }
    }
  }
}
</script>
