<template>
  <div id="nav">
    <navigation/>
    <router-view></router-view>
    <footer/>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'

export default {
  name: 'app',
  components: {
    Navigation,
  }
}
</script>
